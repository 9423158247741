import request from '@/utils/request'

// 查询我的待办列表
export function myPending(query) {
  return request({
    url: '/iot/approvalProcessForm/myPending',
    method: 'get',
    params: query
  })
}

// 查询发起的审批
export function myInitiatedpproval(query) {
  return request({
    url: '/iot/approvalProcessForm/myInitiatedpproval',
    method: 'get',
    params: query
  })
}

// 查询已办理的审批
export function myApproved(query) {
  return request({
    url: '/iot/approvalProcessForm/myApproved',
    method: 'get',
    params: query
  })
}

// 审批处理
export function completeApproval(data) {
  return request({
    url: '/iot/approvalProcessForm/completeApproval',
    method: 'post',
    data: data
  })
}

// 查看审批进度
export function getApprovalProcess(id) {
  return request({
    url: '/iot/approvalProcessForm/getApprovalProcess/' + id,
    method: 'get'
  })
}

// 查看所有审批单
export function list(query) {
  return request({
    url: '/iot/approvalProcessForm/list',
    method: 'get',
    params: query
  })
}

// 设定抄送人
export function configCandidateNotifyUsers(data) {
  return request({
    url: '/iot/approvalProcessForm/configCandidateNotifyUsers',
    method: 'post',
    data: data
  })
}
